// FONTS
@font-face {
  font-family: "GinoraSans";
  src: local("GinoraSans"), url(./assets/fonts/GinoraSans.otf) format("opentype");
  font-display: swap;
}
@font-face {
  font-family: "GinoraSans";
  src: local("GinoraSans"), url(./assets/fonts/GinoraSans-Bold.otf) format("opentype");
  font-weight: bold;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

@import "~bootstrap/scss/bootstrap";

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "GinoraSans", serif;
  color: #6A585A;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFE8B7;
  
  // Disable doubletap to zoom
  touch-action: manipulation;
}

body.winter {
  background-color: #EBF8FF;
}

h1, h2, h3 {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  border: none;
  font-family: "GinoraSans", serif;
  color: black;
  background-color: transparent;
}

button:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

$placeholder-color: rgba(0,0,0, 0.40);

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $placeholder-color;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $placeholder-color;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $placeholder-color;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholder-color;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholder-color;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: $placeholder-color;
}

@media (max-width: 480px) {
  .container {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.modal-backdrop.show {
  opacity: 0
}